import company from '@i18n/locales/ua/components/company'
import { TLevel } from '@pages/Services/normalizers'

type TList = { [key in string]: string }

interface IParagraphs {
  SERVICES: string
  NUMBER_OF_USERS: (number: string) => string
  STATISTIC: string
  STATISTIC_LINK: string
  LEADING_1: string
  LEADING_2: string
  LEADING_3: string
  LEADING_4: string
  INFO_ORDER_STEP_1: string
  INFO_ORDER_STEP_2: string
  INFO_ORDER_STEP_3: string
  INFO_ORDER_STEP_4: string
  INFO_ORDER_1: string
  INFO_ORDER_2: string
  INFO_ORDER_3: string
  INFO_ORDER_4: string
  INFO_ORDER_SUCCESS: string
  CONTINUATION_SUBSCRIPTION_1: string
  CONTINUATION_SUBSCRIPTION_2: string
  CONTINUATION_SUBSCRIPTION_3: string
  CONTINUATION_SUBSCRIPTION_LIST_TITLE: string
  CONTINUATION_SUBSCRIPTION_LIST_1_BOLD: string
  CONTINUATION_SUBSCRIPTION_LIST_1: string
  CONTINUATION_SUBSCRIPTION_LIST_2_BOLD: string
  CONTINUATION_SUBSCRIPTION_LIST_2: string
  CONTINUATION_SUBSCRIPTION_LIST_3_BOLD: string
  CONTINUATION_SUBSCRIPTION_LIST_3: string
  GROUP_SUBSCRIPTION_1: string
  GROUP_SUBSCRIPTION_2: string
  GROUP_SUBSCRIPTION_3: string
  GROUP_SUBSCRIPTION_LIST_TITLE: string
  GROUP_SUBSCRIPTION_LIST_1_BOLD: string
  GROUP_SUBSCRIPTION_LIST_1: string
  GROUP_SUBSCRIPTION_LIST_2_BOLD: string
  GROUP_SUBSCRIPTION_LIST_2: string
  GROUP_SUBSCRIPTION_LIST_3_BOLD: string
  GROUP_SUBSCRIPTION_LIST_3: string
  GROUP_SUBSCRIPTION_NUMBER_SCHOOLS: string
  GROUP_SUBSCRIPTION_MORE: string
  GROUP_SUBSCRIPTION_MORE_DISCOUNT: string
  DISCOUNT_INFO: string
}

export interface ITServicesContent {
  levels: { [key in TLevel]: string }
  features: TList
  description: TList
  tooltips: TList
  headers: TList
  paragraphs: IParagraphs
  buttons: TList
  price: {
    CURRENCY: string
    ACTION: string
    TITLE: string
    MONTHS_3: string
    MONTHS_6: string
    MONTHS_6_DISCOUNT: (value: number) => string
  }
}

export interface ITServices {
  meta: {
    TITLE_SHORT: string
    TITLE: string
    DESCRIPTION: string
  }
  content: ITServicesContent
}

const lang: {
  services: ITServices
} = {
  services: {
    meta: {
      TITLE_SHORT: 'Послуги сайта SchoolHub',
      TITLE:
        'Платні послуги для шкіл на SchoolHub: вибір пакетів послуг для оптимального розквіту',
      DESCRIPTION:
        'Ознайомтеся з нашим списком платних послуг для шкіл на SchoolHub. Вибирайте з різноманітних опцій, щоб підвищити видимість вашого навчального закладу.',
    },
    content: {
      levels: {
        free: 'Free',
        silver: 'Silver',
        gold: 'Gold',
        platinum: 'Platinum',
      },
      features: {
        REGISTRATION: 'Реєстрація школи',
        OFFICE: 'Кабінет школи',
        EDIT_DESCRIPTION: 'Опція редагування опису школи',
        EDIT_LOGO: 'Можливість зміни логотипу та фотографій',
        EDIT_CONTACTS: 'Керування контактами школи',
        CLOSE_COMMENTS: 'Закриття коментарів',
        REPLY_COMMENTS: 'Відповіді на коментарі від імені школи',
        ADVANCED_EDIT_DESCRIPTION:
          'Розширені можливості редагування опису школи',
        VIDEO: 'Відеопрезентація школи на сторінці школи',
        SEARCH_LOGO: 'Логотип на сторінці пошуку',
        SEARCH_VIP: 'VIP дизайн на сторінці пошуку',
        SCHOOL_VIP: 'VIP статус на сторінці школи',
        SEARCH_PRIORITY: 'Пріоритет у пошуку в рамках міста',
        SCHOOL_DOFOLLOW_LINK: 'Пряме Dofollow посилання на сайт школи',
        ADS_LIST:
          'Назва школи присутня в списку структурованого опису в рекламі Google Ads',
        PICK_UP_SILVER_GOLD:
          'Три безкоштовних підняття на сторінці пошуку серед Silver та Gold',
        SCHOOL_CLOSEST:
          'Розміщення в розділі “Найближчі” на сторінці інших школ',
        MAP_CLOSEST:
          'Розміщення на мапі міста як “Найближчий” заклад на сторінці іншої школи',
        MAP_VIP: 'VIP дизайн на мапі міста',
        HOME_LOGO: 'Логотип на головній сторінці',
        SEARCH_PRIORITY_PLATINUM:
          'Пріоритет розміщення в результатах пошуку в рамках міста над Silver та Gold',
        SEARCH_RESULT_ANY:
          'Присутність в результатах пошуку по тексту при не співпадінні',
        PICK_UP_PLATINUM:
          'Три безкоштовних підняття на сторінці пошуку серед Platinum',
      },
      description: {
        REGISTRATION:
          'На SchoolHub ми постійно доповнюємо наш каталог новими навчальними закладами, доданими нашими адміністраторами. Але ми також вітаємо можливість додавання вашої школи особисто.',
        REGISTRATION_SNACKBAR:
          'Якщо ви бажаєте самостійно додати вашу школу до нашого списку, надішліть нам запит на електронну адресу, і ми з радістю допоможемо вам це зробити.',
        OFFICE: 'Не доступно!!!!',
        EDIT_DESCRIPTION:
          'Ми розуміємо, що ніхто не знає вашу школу краще за вас. Тому у Вас є можливість змінювати опис вашого навчального закладу.',
        EDIT_DESCRIPTION_SNACKBAR:
          'Зараз ця можливість доступна через нашу електронну адресу. Просто надішліть нам оновлений опис, і ми відразу оновимо вашу сторінку, забезпечуючи вам контроль над тим, як ви хочете представити вашу школу на нашому сайті.',
        EDIT_LOGO:
          'Ми розуміємо, наскільки важливо відображення вашого навчального закладу унікальним та привабливим способом. Тому у Вас є можливість змінювати логотип та фотографії вашої школи.',
        EDIT_LOGO_SNACKBAR:
          'Наразі, ця можливість доступна за допомогою нашої електронної адреси. Просто надішліть нам ваші зміни, і ми оперативно оновимо вашу сторінку, допомагаючи вам створити яскраве та виразне представлення вашої школи на нашому сайті.',
        EDIT_CONTACTS:
          "Ми розуміємо, що актуальні контактні дані важливі для забезпечення зв'язку з вашою школою. Тому ми пропонуємо зміну контактних даних, включаючи пошту, адресу, телефони, а також ваші профілі в соціальних мережах.",
        EDIT_CONTACTS_SNACKBAR:
          "Наразі, ця можливість доступна через нашу електронну адресу. Просто надішліть нам оновлені контактні дані, і ми оперативно оновимо вашу сторінку, забезпечуючи точну та актуальну інформацію для зв'язку з вашою школою на нашому сайті.",
        CLOSE_COMMENTS:
          'Ми надаємо вам можливість закрити всі коментарі на сторінці вашої школи, дозволяючи забезпечити приватність та захист від будь-яких небажаних коментарів. Це легка та зручна опція, яка дозволяє вам залишати контроль лише в вашому руслі.',
        REPLY_COMMENTS:
          'Маєте бажання взяти участь в дискусіях як представник вашої школи? Наша функція відповідей від імені школи дозволяє вам це зробити легко та ефективно. Ви зможете взаємодіяти з батьками та учнями, давати пояснення та відповіді на їх питання, всі це від імені вашого навчального закладу. Просто активуйте цю опцію у налаштуваннях, і ви станете активним учасником діалогу на сторінці вашої школи.',
        ADVANCED_EDIT_DESCRIPTION: 'Не доступно!!!!',
        VIDEO:
          'Приверніть увагу батьків та учнів за допомогою відео-презентації з Youtube на сторінці вашої школи. Ви маєте можливість додати відео, яке розповість про особливості вашого навчального закладу та покаже його атмосферу. Якщо У Вас немає Youtube канала, ми можемо розмістити Ваше відео на каналі SchoolHub.',
        VIDEO_SNACKBAR:
          'Зараз ця можливість доступна через нашу електронну адресу. Просто надішліть нам посилання на відео-ролик на Youtube, і ми додамо його на сторінку школи.',
        SEARCH_LOGO:
          'Додайте вашу школу до ексклюзивного розділу "Провідні школи" на сторінці пошуку. Цей розділ надає можливість вашому навчальному закладу виділитися серед інших і залучити увагу батьків та учнів. Ваш логотип буде відображатися поряд з вашим списком у розділі "Провідні школи", надаючи вашому навчальному закладу престижний статус та особливий зв\'язок з цільовою аудиторією. ',
        SEARCH_VIP:
          'Підкресліть видимість вашої школи за допомогою VIP дизайну на сторінці пошуку. Ваш навчальний заклад буде вирізнятися на тлі інших шкіл, забезпечуючи вам важливу перевагу у залученні уваги батьків та учнів. Цей VIP дизайн дозволить вам бути помітним та відзначитися у списку навчальних закладів. Оберіть цю функцію, і ваша школа буде привертати погляди ще до першого погляду на сторінку пошуку.',
        SCHOOL_VIP:
          'Зробіть свою школу впізнаваємою за допомогою VIP дизайну на її сторінці. Цей дизайн дозволяє вирізнятися вашому навчальному закладу на фоні інших, забезпечуючи вам видимість та перевагу. З мінімальними, але важливими змінами, ваша сторінка буде привертати більше уваги та вражати учнів і батьків. Оберіть цю функцію, і ваша школа стане справжнім лідером на сторінці пошуку навчальних закладів.',
        SEARCH_PRIORITY:
          'Збережіть увагу користувачів, надаючи вашій сторінці особливий VIP дизайн. Це дозволить підкреслити унікальність вашої школи.',
        SCHOOL_DOFOLLOW_LINK:
          'Зміцніть свою присутність у пошукових системах завдяки функції Dofollow посилання на сторінці вашої школи. Ця можливість дозволить вам забезпечити підвищену видимість та рейтинг у пошукових запитах. При активації цієї функції, посилання з вашої сторінки будуть збільшувати авторитет та позиції вашого навчального закладу в результатах пошуку. Зробіть свою школу більш помітною для пошукових систем та потенційних клієнтів, вибравши цю опцію для покращення вашого SEO.',
        ADS_LIST:
          "Збільшіть впізнаваність вашої школи завдяки функції, що включає назву вашого навчального закладу до структурованого списку в рекламі SchoolHub на Google Ads. Ця можливість додає вашу школу до вибраного списку шкіл, які представлені в рамках рекламної кампанії SchoolHub. Ваш бренд буде помітним та здатним залучити більше уваги вже на початковому етапі пошуку. Ваша школа стане невід'ємною частиною рекламної кампанії SchoolHub, допомагаючи вам привернути більше клієнтів та підвищити впізнаваність бренду.",
        PICK_UP_SILVER_GOLD:
          'Ми пропонуємо вам три безкоштовні підняття в результатах пошуку серед Silver та Gold пакетів. Це дозволяє вам підняти позицію вашої школи на сторінці результатів пошуку, надаючи їй більш видиме місце та більше шансів бути поміченою користувачами.',
        PICK_UP_SILVER_GOLD_SNACKBAR:
          'Просто відправте запит на нашу поштову адресу, і ми забезпечимо підняття вашої школи на вищий рівень видимості.',
        SCHOOL_CLOSEST:
          'Приверніть увагу до вашої школи завдяки розміщенню у блоку "Найближчі" на сторінках інших навчальних закладів. Ваша школа буде представлена поруч з 3 найближчими провідними навчальними закладами, надаючи батькам та учням можливість розглянути альтернативи близько від їхнього місця проживання. Цей блок допоможе позиціонувати вашу школу як зручний вибір, що може конкурувати з іншими провідними навчальними закладами.',
        MAP_CLOSEST:
          'Виділіть вашу школу на мапі, розмістивши її в списку з трьох провідних найближчих навчальних закладів, що доповнює той, на якому зараз знаходиться користувач. Це дозволяє відвідувачам побачити вашу провідну школу як альтернативу, яка знаходиться в їхньому непосреджньому середовищі. Така можливість допоможе зробити обґрунтований вибір та відкриває можливість для розгляду вашої школи у контексті її конкурентів.',
        MAP_VIP:
          'Забезпечте особливу видимість вашої школи завдяки VIP іконці на мапі, яка буде присутня на сторінці пошуку та на сторінці вашої школи. Ця іконка виділить Вашу школу серед інших, забезпечуючи вам особливий статус і привертаючи більше уваги від батьків та учнів. Ваша школа буде помітною на мапі, надаючи змогу виділитися в шумному світі освітніх закладів.',
        HOME_LOGO:
          'Піднесіть свій бренд на вищий статус завдяки виділенню вашого логотипу на головній сторінці. Ця функція надасть вашому навчальному закладу видатну видимість та статусний вигляд, дозволяючи вам вибитися на фоні інших шкіл. Ваш логотип буде відображатися з почесним статусом, показуючи важливість вашої школи.',
        SEARCH_PRIORITY_PLATINUM:
          'Підніміть вашу школу на вищий рівень завдяки пріоритетному позиціонуванню в результатах пошуку в рамках вашого міста, що перевищує позиції Silver та Gold пакетів. Ця функція дозволяє вашій школі виступити в ролі провідного вибору серед інших навчальних закладів. Виберіть цей функціонал, щоб забезпечити вашій школі найвищий пріоритет у видачі пошуку, збільшуючи вірогідність звернень батьків та учнів до вашого закладу.',
        SEARCH_RESULT_ANY:
          'Забезпечте вашу школу стабільною видимістю в результатах пошуку, навіть якщо використовуються широкі набори фільтрів. Ця функція гарантує, що ваша VIP школа буде завжди присутня в списку підходящих освітніх закладів, навіть якщо запит користувача включає велику кількість параметрів. Обирайте цей варіант, щоб впевнено діставати увагу батьків та учнів незалежно від великого обсягу фільтрів, що застосовуються у їхньому пошуку.',
        PICK_UP_PLATINUM:
          'Отримайте можливість провести три безкоштовних підняття вашої школи на видимі позиції серед Platinum навчальних закладів у результатах пошуку. Ця функція надає вам шанс висунутися на передові позиції, забезпечуючи вашу школу першочерговою видимістю для батьків та учнів.',
        PICK_UP_PLATINUM_SNACKBAR:
          'Просто відправте запит на нашу поштову адресу.',
      },
      tooltips: {
        MANUAL:
          'Тимчасово (до створення кабінету шкіл) доступно по запиту через email',
      },
      price: {
        CURRENCY: 'грн',
        TITLE: 'Вартість',
        ACTION: 'Акція до 15 травня',
        MONTHS_3: '3 місяці, грн',
        MONTHS_6: '6 місяців, грн',
        MONTHS_6_DISCOUNT: (discount) => `Знижка ${discount}%`,
      },
      headers: {
        SERVICES: 'Послуги сайта SchoolHub',
        LEADING: 'Провідна школа',
        INFO_ORDER: 'Замовлення розміщення провідної школи',
        FEATURE_DESCRIPTIONS: 'Детальний опис послуг',
        ADDITIONAL_DISCOUNTS: 'Додаткові знижки',
        CONTINUATION_SUBSCRIPTION: 'Продовження підписки',
        GROUP_SUBSCRIPTION: 'Групова підписка',
      },
      paragraphs: {
        SERVICES:
          'SchoolHub — це провідний освітній портал в Україні, що пропонує найповніший та найзручніший каталог шкіл. Тут зібрана докладна інформація про різні навчальні заклади, дозволяючи батькам та учням знайти оптимальну освітню установу.',
        NUMBER_OF_USERS: (number: string) =>
          `Більше ${number} користувачів за місяць`,
        STATISTIC: 'Повна статистика проекту доступна на сторінці ',
        STATISTIC_LINK: 'Статистика сайту',
        LEADING_1:
          'Ми пропонуємо незрівнянні можливості для вашої освітньої установи вийти на передній план та вразити вашу цільову аудиторію.',
        LEADING_2:
          'Наша мета - допомогти вам реалізувати повний потенціал вашої школи, забезпечуючи доступ до широкого спектру додаткових платних послуг. З цими послугами ви матимете можливість підвищити видимість вашого навчального закладу, залучити увагу батьків та учнів.',
        LEADING_3:
          'Ваша школа зможе вибирати серед різноманітних пропозицій, включаючи розміщення промо-відео на сторінці школи, ексклюзивний дизайн та вигідні позиції в пошукових результатах. Також ви матимете можливість отримати VIP статус, який додатково підкреслить вашу унікальність та кращі характеристики.',
        LEADING_4:
          'Подаруйте вашій школі можливість сяяти на SchoolHub. Оберіть опції, які найкраще відповідають вашим потребам, і допоможіть вашій школі стати лідером в освітньому просторі.',
        INFO_ORDER_STEP_1: 'Крок 1',
        INFO_ORDER_STEP_2: 'Крок 2',
        INFO_ORDER_STEP_3: 'Крок 3',
        INFO_ORDER_STEP_4: 'Крок 4',
        INFO_ORDER_1: `Відправити запит на пошту ${company.company.EMAIL}. Вказати школу і вибраний пакет послуг.`,
        INFO_ORDER_2: 'Отримати рахунок-фактуру для оплати.',
        INFO_ORDER_3: 'Перевести кошти на вказаний рахунок.',
        INFO_ORDER_4: 'Отримати акт виконаних робіт.',
        INFO_ORDER_SUCCESS:
          'Ваша школа підключена до вибраного пакету провідної школи!',
        CONTINUATION_SUBSCRIPTION_1:
          'Економте при продовженні підписки на SchoolHub.',
        CONTINUATION_SUBSCRIPTION_2:
          'Ми вдячні за ваш вибір SchoolHub для просування вашого навчального закладу. Дякуємо за довіру та підтримку!',
        CONTINUATION_SUBSCRIPTION_3: 'Знижка 10% при продовженні підписки!',
        CONTINUATION_SUBSCRIPTION_LIST_TITLE: 'Як це працює:',
        CONTINUATION_SUBSCRIPTION_LIST_1_BOLD: 'Продовжуйте підписку.',
        CONTINUATION_SUBSCRIPTION_LIST_1:
          'Оберіть продовження вашої підписки на SchoolHub, щоб продовжувати користуватися всіма перевагами нашого сервісу.',
        CONTINUATION_SUBSCRIPTION_LIST_2_BOLD: 'Отримайте 10% знижку.',
        CONTINUATION_SUBSCRIPTION_LIST_2:
          "Зв'яжіться з Адміністрацією SchoolHub для отримання знижки. При продовженні вашої підписки ви отримуєте додаткову знижку 10% на обраний пакет.",
        CONTINUATION_SUBSCRIPTION_LIST_3_BOLD: 'Зберігайте свої вигоди.',
        CONTINUATION_SUBSCRIPTION_LIST_3:
          'Продовжуйте привертати увагу та залучати нових учнів, заощаджуючи при цьому.',
        GROUP_SUBSCRIPTION_1:
          'Знижка за групову підписку на навчальні заклади однієї франшизи на SchoolHub!',
        GROUP_SUBSCRIPTION_2:
          'Розглядаєте можливість просування кількох навчальних закладів своєї франшизи? Тоді ми маємо для вас чудову новину! SchoolHub пропонує спеціальну знижку за групову підписку, яка дозволяє вам економити значну суму при розміщенні кількох закладів на нашому сайті.',
        GROUP_SUBSCRIPTION_3:
          'Підніміть свою франшизу на новий рівень разом із SchoolHub. Економте та отримайте максимум від розміщення своїх закладів на нашому платформі!',
        GROUP_SUBSCRIPTION_LIST_TITLE: 'Як це працює:',
        GROUP_SUBSCRIPTION_LIST_1_BOLD:
          "Об'єднайте кілька закладів однієї франшизи.",
        GROUP_SUBSCRIPTION_LIST_1:
          'Cтворіть єдину картку закладів для більш зручного вибору.',
        GROUP_SUBSCRIPTION_LIST_2_BOLD: 'Отримайте знижку.',
        GROUP_SUBSCRIPTION_LIST_2:
          "Зв'яжіться з Адміністрацією SchoolHub для отримання знижки. Розмір знижки представлений в таблиці нижче.",
        GROUP_SUBSCRIPTION_LIST_3_BOLD: 'Економте разом із груповою знижкою.',
        GROUP_SUBSCRIPTION_LIST_3:
          'Сума знижки на кожен заклад залежить від їх кількості та обраного пакету. Чим більше закладів у вашій групі та чим вищий пакет, тим вища знижка!',
        GROUP_SUBSCRIPTION_NUMBER_SCHOOLS: 'Кількість закладів',
        GROUP_SUBSCRIPTION_MORE: 'Більше',
        GROUP_SUBSCRIPTION_MORE_DISCOUNT: 'Персональна знижка',
        DISCOUNT_INFO: '* Знижки додаються до інших акційних знижок',
      },
      buttons: {
        ORDER: 'Замовити',
      },
    },
  },
}

export default lang
