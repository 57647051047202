import React, { ReactElement, ReactNode, Ref } from 'react'
import ButtonComponent from '@mui/material/Button'
import { CircularProgress } from '@ui'

interface IProps {
  children: ReactNode
  classes?: object
  className?: string
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  component?: any
  loading?: boolean
  disabled?: boolean
  endIcon?: ReactNode
  ref?: Ref<any>
  fullWidth?: boolean
  href?: string
  onClick?: (arg0?: any) => any
  size?: 'large' | 'medium' | 'small'
  startIcon?: ReactNode
  type?: string
  variant?: 'contained' | 'outlined' | 'text'
  sx?: object
}

export default function Button({
  children,
  classes,
  className,
  color,
  component,
  loading,
  disabled,
  endIcon,
  ref,
  fullWidth,
  href,
  onClick,
  size,
  startIcon,
  type,
  variant,
  ...selfProps
}: IProps): ReactElement {
  const startIconValue = loading ? <CircularProgress size={22} /> : startIcon

  return (
    <ButtonComponent
      classes={classes}
      className={className}
      color={color}
      component={component}
      disabled={disabled || loading}
      endIcon={endIcon}
      ref={ref}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      size={size}
      startIcon={startIconValue}
      type={type}
      variant={variant}
      {...selfProps}
    >
      {children}
    </ButtonComponent>
  )
}
