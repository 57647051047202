export interface ILocalStorageConstants {
  LAST_PAGE: string
  COPY_STORE: string
  PAGE_LIMIT: string
  CURRENT_USER: string
  EMAIL: string
  VISIBILITY: string
  ARTICLES_VISIBILITY: string
  SORTING: string
  ADSENSE_INIT: string
  IS_MAP: string
  ACTION_INIT: string
}

const localStorageConstants: ILocalStorageConstants = {
  LAST_PAGE: 'last_page',
  COPY_STORE: 'copy_store',
  PAGE_LIMIT: 'page_limit',
  CURRENT_USER: 'cur_user',
  EMAIL: 'email',
  VISIBILITY: 'visibility',
  ARTICLES_VISIBILITY: 'articles_visibility',
  SORTING: 'sorting',
  ADSENSE_INIT: 'adsense_init',
  IS_MAP: 'is_map',
  ACTION_INIT: 'action_init',
}

export default localStorageConstants
